<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }" style="background-color: #ffffff">
    <div class="d-flex flex-column justify-content-center login">
      <!-- <img class="logo" src="https://rsnd.undip.ac.id/wp-content/uploads/2022/03/LOGO_RSND-removebg-preview.png" alt=""> -->
      <div style="width: 50%; background-color: ; display: flex; justify-content: flex-start; padding-left: 15px">
        <b-img :src="require(`../../assets/icons/kudus.png`)" style="width: 20%"></b-img>
      </div>
      <CForm>
        <!-- <p class="text-muted">Sign In to your account</p> -->
        <b-alert dismissible fade :show="showing" :variant="variant">{{ msg }}</b-alert>
        <CInput placeholder="Silahkan Isi URL Terlebih Dahulu" v-model="url" class="mb-3 mt-2">
          <template #prepend-content>
            <CIcon name="cil-settings" />
          </template>
        </CInput>

        <label for="username">Username</label>
        <CInput placeholder="Username" id="username" v-model="username" class="mb-3 mt-2">
          <template #prepend-content>
            <CIcon name="cil-user" />
          </template>
        </CInput>

        <label for="username">Password</label>
        <CInput placeholder="Password" id="password" class="mb-3 mt-2" type="password" v-model="password" v-on:keyup.enter="signin()">
          <template #prepend-content>
            <CIcon name="cil-lock-locked" />
          </template>
        </CInput>

        <CButton @click="signin()" color="primary" class="px-4 submitButton">
          <b-spinner v-if="loading" variant="light" label="Spinning"></b-spinner>
          <!-- <span v-if="loading">Mohon tunggu</span> -->
          <span v-else>Login</span>
        </CButton>
      </CForm>
    </div>
  </div>
</template>

<style>
.login {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding-left: 10%;
  background: #fff;
}

.login .logo {
  width: 15%;
  min-width: 200px;
  margin-bottom: 1rem;
}

.login form {
  width: 50%;
  z-index: 10;
  position: relative;
}

.form-control {
  height: calc(1.5em + 1.2rem + 2px);
}

.submitButton {
  height: calc(1.5em + 1rem + 2px);
}
</style>

<script>
import axios from "axios";
import { setToken } from "../../store/utils/auth";
// import { ipBackend } from "@/ipBackend.js";
import stores from "@/store/store";
import _ from "lodash";
// const moment = require('moment');

// function getWeekOneDateRange(year, month) {
//   const firstDayOfMonth = moment({ year, month, day: 1 });
//   const startOfWeekOne = firstDayOfMonth.startOf('isoWeek');
//   const endOfWeekOne = startOfWeekOne.clone().add(6, 'days').endOf('day');

//   return { start: startOfWeekOne, end: endOfWeekOne };
// }

// function getStartOfWeekTwo(year, month) {
//   const firstDayOfMonth = moment({ year, month, day: 1 });
//   const startOfWeekTwo = firstDayOfMonth.add(1, 'weeks').startOf('isoWeek');

//   return startOfWeekTwo;
// }

// const year = 2023; // Change this to the desired year
// const month = 7;   // Change this to the desired month (0-indexed, so January is 0)

// const { start, end } = getWeekOneDateRange(year, month);
// console.log(`The date range for week 1 of ${moment.months()[month]} ${year} is from ${start.format('YYYY-MM-DD')} to ${end.format('YYYY-MM-DD')}.`);
// const startOfWeekTwo = getStartOfWeekTwo(year, month);
// console.log(`The start of week 2 of ${moment.months()[month]} ${year} is on ${startOfWeekTwo.format('YYYY-MM-DD')}.`);
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      url: "",
      showing: false,
      loading: false,
      variant: "success",
      msg: "",
      src: "../../assets/icons/kudus.png",
    };
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    sidebar_background() {
      return this.$store.state.color.sidebar_background;
    },
    logo() {
      return this.$store.state.logo;
    },
    // sidebar_items() {
    //   console.log("this.$store.state.sidebar_items", this.$store.state.sidebar_items);
    //   return this.$store.state.sidebar_items;
    // }
  },
  // mounted(){
  //   this.getLogo()
  // },
  beforeMount() {
    if (stores.state.user_token) {
      let x = stores.state.user_token;
      setToken(x);
      this.$router.push({ path: "/admin" });
    }
    this.url = localStorage.getItem("url") || this.url;
  },
  mounted(){
    this.$store.commit("set_logo", "kudus.png");
  },
  methods: {
    // async getLogo(){
    //   let logo = await this.$axios("/puskesmas/logo_dinkes")
    //     console.log(logo, 'ini logo dinkes');
    //     this.$store.commit("set_logo", `${this.url}/${logo.data.data[0].logo_dinkes}`);
    //     console.log(this.logo, 'ini logonya');
    // },
    signin() {
      let vm = this;
      vm.loading = !false;
      setTimeout(() => {
        vm.loading = !true;
      }, 3000);
      axios
        .post(vm.url + "/user/login", {
          username: vm.username,
          password: vm.password,
        })
        .then((res) => {
          console.log(res, "ini ress login");
          if (res.data.message == "sukses" && res.data.data.length) {
            // console.log("token", res.data.data[0].token);
            let x = res.data.data[0];
            let data_puskesmas = {
              puskesmas_id: x.puskesmas_id,
              nama_puskesmas: x.nama_puskesmas,
              nama_provinsi: x.nama_provinsi,
              nama_kota: x.nama_kota,
              nama_kecamatan: x.nama_kecamatan,
              nama_kelurahan: x.nama_kelurahan,
              tarif_pendaftaran: x.tarif_pendaftaran,
              alamat_puskesmas: x.alamat_puskesmas,
              no_telp_pengaduan: x.no_telp_pengaduan,
              email_pengaduan: x.email_pengaduan
            };

            this.$store.commit("set_login", x);
            this.$store.commit("set_token", res.data.data[0].token);
            this.$store.commit("set_role", res.data.data[0].nama_role);
            this.$store.commit("set_real_role", res.data.data[0].nama_role);
            this.$store.commit("set_role_id", res.data.data[0].ms_role_id);
            this.$store.commit("set_real_role_id", res.data.data[0].ms_role_id);
            this.$store.commit("set_puskesmas", res.data.data[0].puskesmas_id);
            this.$store.commit("set_puskesmas_full", data_puskesmas);
            this.$store.commit("set_alert", { variant: "success", msg: "BERHASIL TERHUBUNG" });
            this.$store.commit("set_top_list", []);
            this.$store.commit("set_logo", `${this.url}/${res.data.data[0].logo_puskesmas}`);
            this.$store.commit("set_config_ip", vm.url);
            this.$store.commit("set_nama_user_login", res.data.data[0].nama);
            this.$store.commit("set_organization_id", res.data.data[0].organization_id);

            localStorage.setItem("url", vm.url);
            localStorage.setItem("puskesmas", res.data.data[0].token);
            localStorage.setItem("idp", res.data.data[0].puskesmas_id);
            localStorage.setItem("nama_puskesmas", res.data.data[0].nama_puskesmas);
            localStorage.setItem("alamat_puskesmas", res.data.data[0].alamat_puskesmas);
            localStorage.setItem("trf", res.data.data[0].tarif_pendaftaran);
            
            // this.getLogo()
            console.log('this.$store.state', this.$store.state)
            if(this.$store.state.user_role == 'dinkes'){
              setTimeout(() => {
                // vm.$router.push({ path: "/dinkes" });
                window.location.href = '/dinkes';
              }, 2000);
            }else{
              setTimeout(() => {
                vm.$router.push({ path: "/dashboard-puskesmas" });
                // window.location.href = '/dashboard-puskesmas';
              }, 2000);
            }
          } else {
            vm.showing = true;
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            setTimeout(() => {
              vm.showing = false;
            }, 3000);
          }
        })
        .catch((err) => {
          vm.showing = true;
          vm.variant = "danger";
          vm.msg = _.toUpper(err);
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
  },
};
</script>
